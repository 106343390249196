import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

import Accueil from "../containers/home-accueil"
import PetitsPlus from "../containers/home-les-petits-plus"
// import Cours from "../containers/home-cours"
import Cartonnage from "../containers/home-cartonnage"
import Citation from "../containers/home-citation"
import Encadrement from "../containers/home-encadrement"
import GunnarIntro from "../containers/gunnar-intro"

const IndexPage = () => (

  <Layout navClass="changeNav">
    <SEO title="Accueil" />
    <Helmet>
        <script src={withPrefix('navbar.js')} type="text/javascript"></script>
    </Helmet>


    <Accueil />
    {/* <Cours /> */}
    <Cartonnage />
    <Encadrement />
    <Citation />
    <GunnarIntro />
    <PetitsPlus />
  </Layout>

)

export default IndexPage
