import React from "react"
import Bandeau from "../components/bandeau"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const Encadrement = () => (
  <Bandeau title="Encadrement" dark>
    <Row className="d-flex justify-content-center">
     <p className="text-center">
      Mise en valeur d’une œuvre d’art, d’un tableau, d’un objet ou d’un sujet.
      Je vous propose un travail traditionnel d’encadrement ou de créer une mise
      en valeur plus recherchée, liée à mon ressenti et à ma créativité tout en
      restant à votre écoute.
      </p>
      <p className="text-center">
      Mes exigences professionnelles me conduisent à m’entourer de
      professionnels qualifiés pour la restauration de gravures anciennes ou de
      tableaux anciens.
      </p>
      <br></br>
    </Row>

    <Row>
      <Col lg={4} >
        <StaticImage
                src='../images/cadre_1.jpg'
          alt="First slide"
          className='roundedPict'
              />
      </Col>
      <Col lg={4} >
        <StaticImage
                src='../images/cadre_2.jpg'
          alt="First slide"
          className='roundedPict'
              />
      </Col>
      <Col lg={4} >
        <StaticImage
                src='../images/cadre_3.jpg'
          alt="First slide"
          className='roundedPict'
              />
      </Col>


    </Row>
    

    <Row className="d-flex justify-content-center mt-5">
     <p className="text-center">
      <b>Tension à l’ancienne de vos ouvrages (Broderie et Tapisserie)</b>
      </p>
      <p className="text-center">
      
      Il s’agit de tendre votre travail au fil afin que trame et chaîne soient
      parfaitement alignées. Ceci garantit la mise en valeur de vos ouvrages et
      surtout les toiles ne bougeront pas.
      </p>
    </Row>
  </Bandeau>
)
export default Encadrement
