import React from "react"
import Bandeau from "../components/bandeau"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const GunnarIntro = () => (

    <Bandeau title="Commande Numérique">
        <Row className="align-items-center ">
            <Col lg={6} >
                <StaticImage
                    className='m-3 roundedPict'
                    src='../images/machine_gunnard.jpg'
                    alt="Machine Gunnard"
                />
            </Col>
            <Col lg={6} >
                <Row className='m-3 justified'>
                    Ma machine Gunnar à commande numérique permet de créer une multitude de Passe-Partout originaux.
                    Nous pouvons ainsi mettre vos photos ou objets en valeur avec une créativité plus élargie.
            </Row>
                <Row className='m-3 justified'>
                    Il est possible de multiplier les fenêtres images avec une grande précision.
                    Il est également possible de multiplier les formes sur vos passe-partout
                    (carré, rond, rectangle, coeur, ballon,...). Tout devient possible avec la Formule 1 hybrid Gunnar.
            </Row>

                <Row className='m-3'>
                    <Link className="nav-link p-0" to="/gunnar">En savoir plus...</Link>
                </Row>
            </Col>
        </Row>
    </Bandeau>
)
export default GunnarIntro
