import React from "react"
import Bandeau from "../components/bandeau"
import { Link } from "gatsby"
import { Row } from "react-bootstrap"
import { Col, Carousel } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

// function getPicturePath(imgName) {
//   const images = require.context("../images/", true)
//   return images("./" + imgName)
// }

const Cartonnage = () => (
  <Bandeau title="Cartonnage">
    <Row className="align-items-center ">
      <Col lg="5">
        <Carousel className="d-block carouselCarton roundedPict">
          <Carousel.Item className='roundedPict'>
            <StaticImage
              // className="d-block w-50"
              src='../images/cartonnage_1r.jpg'
              alt="First slide"
              className='roundedPict'
            />
            {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              // className="d-block w-50"
              src='../images/cartonnage_2r.jpg'
              alt="First slide"
              className='roundedPict'

            />
            {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              // className="d-block w-50"
              src='../images/cartonnage_3r.jpg'
              alt="First slide"
              className='roundedPict'
            />
            {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              // className="d-block w-50"
              src='../images/cartonnage_4r.jpg'
              alt="First slide"
              className='roundedPict'
            />
            {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              // className="d-block w-50"
              src='../images/cartonnage_5r.jpg'
              alt="First slide"
              className='roundedPict'
            />
            {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              // className="d-block w-50"
              src='../images/cartonnage_6r.jpg'
              alt="First slide"
              className='roundedPict'
            />
            {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              // className="d-block w-50"
              src='../images/cartonnage_7r.jpg'
              alt="First slide"
              className='roundedPict'
            />
            {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
          </Carousel.Item>



        </Carousel>

      </Col>
      <Col lg="7" classname='align-middle'>
        <Row className="d-flex justify-content-center justified">
          Le cartonnage c’est la création d’objets raffinés de décoration pour la maison.
          Une décoration personnalisée et unique aux couleurs de votre choix.
          La satisfaction de réaliser soi-même des objets, le plaisir de créer, le plaisir d’offrir.
          Une activité ouverte à tous dans laquelle chacun se découvre un talent caché au bout des doigts.
          </Row>
        <br /><br />
        <Row>
        <Link className="nav-link p-" to="/atelier">En savoir plus...</Link>
        </Row>
      </Col>
    </Row>



  </Bandeau>
)
export default Cartonnage
