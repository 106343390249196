
import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// import Typist from 'react-typist';
import HoverPict from "../components/hoverpict"
import "../styles/accueil.scss"

const Accueil = () => (
    <Container fluid className="homepage-bgimage d-flex">
        <div
            id="homepage-opacLayer"
            className="d-flex flex-column justify-content-center align-items-center"
        >
            <div id="opacReal">

                <h1 className="text-align-center">Hiva Hoa Création</h1>
                <p></p>
                <p className="text-align-center">La magie au bout des doigts !</p>
            
                <Row className="d-flex justify-content-center m-3">
                    <HoverPict
                        url="https://www.facebook.com/HIVA-HOA-creations-615665421787866/"
                        pictBack="facebook_brown.png"
                        pictFront="facebook_green.png"
                    ></HoverPict>

                    <HoverPict
                        url="https://instagram.com/nadinecomblat_hivahoa_creation?igshid=22cxkgt7b7q3"
                        pictBack="insta_brown.png"
                        pictFront="insta_green.png"
                    ></HoverPict>

                </Row>
            </div>
        </div>
    </Container>
)

export default Accueil