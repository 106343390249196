import React from "react"
import { Container } from "react-bootstrap"
// import Bandeau from "../components/bandeau"

// import { Row } from "react-bootstrap"
// import { Col } from "react-bootstrap"
// import { StaticImage } from "gatsby-plugin-image"
const Citation = () => (
    // <Bandeau title="Citation" dark>
    <Container fluid id='citationHH'>
        
        {/* <Row> */}
            {/* <blockquote> */}
                {/* <StaticImage src='../images/guillemet_green.png' alt="citation" /> */}

                <h2>
                On a tous un talent caché, le bonheur est au bout des doigts 
                </h2>
                <br></br>
        {/* <h2><span role="img" aria-label="arts">🎨</span></h2> */}
                {/* <h2>🙌</h2> */}
                
            {/* </blockquote> */}
        {/* </Row> */}
    </Container>
    // </Bandeau>

)
export default Citation
