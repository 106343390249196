import React from "react"
import Bandeau from "../components/bandeau"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
// import Image from "react-bootstrap/Image"
import { StaticImage } from "gatsby-plugin-image"

// function getPicturePath(imgName) {
//   const images = require.context("../images/", true)
//   return images("./" + imgName)
// }

const PetitsPlus = () => (
  <Bandeau title="Petits plus HivaHoa" dark>
    <Container>
      <Row>
        <h3 className="darkText">Cours de boutis</h3>
      </Row>

      <Row className="align-items-center ">
        <Col sm lg={9} className="pr-3 justified">
          Corinne Peleau, une animatrice talentueuse assure des cours une
          journée par mois à l’atelier Hiva Hoa créations. Elle vous initiera à
          la technique du boutis, une broderie traditionnelle sur toile batiste
          dont elle détient le secret et un savoir-faire qu’elle vous
          transmettra avec passion.
          <br></br>
          <br></br>
          N’hésitez pas à la rejoindre pour vivre une
          belle découverte et passer un bon moment entre copines.
        </Col>
        <Col sm lg={3} className="p-3">
          {/* <Image src={getPicturePath("boutis.jpg")} rounded className="p-3 m-0" /> */}
          {/* <StaticImage src={getPicturePath("boutis.jpg")} alt="A dinosaur" /> */}
          <StaticImage src='../images/boutis_2.jpg' alt="boutis" className='roundedPict' />
        </Col>
      </Row>

      <br></br>

      <Row className="d-flex justify-content-end">
        <h3 className="darkText">Tapisserie</h3>
      </Row>

      <Row className="align-items-center">
        <Col sm lg={3} className="p-3">
          <StaticImage src='../images/tapisserie_1.jpg' alt="tapisserie" className='roundedPict' />
        </Col>

        <Col sm lg={9} className="justified pl-3">

          L’art du tapissier est de restaurer un fauteuil, d’écouter son histoire et de lui permettre de poursuivre son chemin. 
          C’est toujours pour moi un vrai plaisir de partager votre joie à l’issue de la réfection de celui-ci.
          <br></br>
          <br></br>
          Titulaire du CAP du TAPISSIER/DECORATEUR depuis 2013 j’ assure
          également des cours de tapisserie et répond à vos commandes. Diplômée
          par l'Ecole de la Philomatique à Bordeaux.
        </Col>
      </Row>

      <br></br>
    </Container>
  </Bandeau>
)
export default PetitsPlus
